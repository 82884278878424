<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >

      <div
        style="position: absolute; right: 0; top: -50px; font-weight: bold; font-size: 16px;"
        class="text-right"
      >
        #{{ pagination.totalItems || 0 }}
      </div>

      <div class="m-2">
        <b-row>
          <b-col
            cols="6"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="mainPerPage"
              dir="ltr"
              :options="[10, 20, 50, 100]"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <b-col
            v-if="selected && selected.length"
            cols="6"
            class="text-right"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :disabled="loading"
              variant="danger"
              @click="deleteVideos"
            >
              <template v-if="loading">
                <b-spinner small />
                Deleting...
              </template>
              <template v-else>
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span class="align-middle">Delete</span>
              </template>
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="selectableTable"
        class="position-relative"
        :items="reviews"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :busy="isLoading"
        :sort-by.sync="attribute"
        :sort-desc.sync="isDesc"
        empty-text="No matching records found"
      >
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="`${appUrl}${data.item.user.image}`"
                :text="avatarText(data.item.user.name)"
                :href="`https://crmzz.com/u/${data.item.user.username}`"
              />
            </template>
            <b-link
              :href="`https://crmzz.com/u/${data.item.user.username}`"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.user.name }}
            </b-link>
            <small class="text-muted">@{{ data.item.user.username }}</small>
          </b-media>
        </template>

        <template #cell(company)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="`${appUrl}${data.item.company.logo}`"
                :text="avatarText(data.item.company.name)"
                :href="`https://crmzz.com/b/${data.item.company.slug}`"
              />
            </template>
            <b-link
              :href="`https://crmzz.com/b/${data.item.company.slug}`"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.company.name }}
            </b-link>
            <small class="text-muted">@{{ data.item.company.slug }}</small>
          </b-media>
        </template>

        <template #cell(thumbnail)="data">
          <b-media vertical-align="center">
            <b-avatar
              size="lg"
              text="N/A"
              :src="`${appUrl}${data.item.videoImage}`"
            />
          </b-media>
        </template>

        <template #cell(type)="data">
          <b-badge
            v-if="data.item.reviewType == 0"
            variant="info"
          >
            <span>Review Video</span>
          </b-badge>

          <b-badge
            v-else
            variant="primary"
          >
            <span>Rep. Video</span>
          </b-badge>
        </template>

        <template #cell(review)="data">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="btn-icon rounded-circle"
            @click="playVideo(data)"
          >
            <feather-icon icon="PlayIcon" />
          </b-button>
        </template>

        <template #cell(created_at)="data">
          <span class="text-center">{{ data.item.date }}</span>
          <br>
          <span class="text-center">{{ data.item.time }}</span>
        </template>

        <template #cell(action)="data">
          <b-button
            class="btn-icon is-red rounded-circle"
            variant="danger"
            @click="deleteVideo(data)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </template>

        <template #cell(id)="data">
          <b-form-checkbox
            v-model="selected"
            :value="data.item.id"
            plain
            class="vs-checkbox-con"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                <i class="vs-icon feather icon-check" />
              </span>
            </span>
          </b-form-checkbox>
        </template>

        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <p class="mt-1">
              <strong>Loading...</strong>
            </p>
          </div>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="paginationCurrentPage"
              :total-rows="pagination.totalItems || 0"
              :per-page="mainPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="paginate"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-modal
      id="modal-lg"
      ref="review-video"
      hide-footer
      centered
      size="xl"
    >
      <GhostPlayer
        class="video-player-box"
        :width="600"
        :height="260"
        :src="video.videoUrl"
        :poster="video.videoImage"
        :token="video.videoToken"
      />
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import { appUrl } from '@/config/apiConfig'
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BDropdown, BDropdownItem, BPagination, VBTooltip, BAvatarGroup, BSpinner,
  BFormCheckbox, BEmbed, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import GhostPlayer from '@core/components/GhostPlayer'
import { mapGetters, mapActions } from 'vuex'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    BAvatarGroup,
    BFormCheckbox,
    BSpinner,
    vSelect,
		BEmbed,
		BBadge,
		GhostPlayer,
  },
  setup() {
    return {
      // Filter
      avatarText,
    }
  },
	directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
			appUrl,
			video: {},
      selected: [],
      loading: false,
			isLoading: false,
      tableColumns: [
        { key: 'user', label: 'User', sortable: true },
        { key: 'company', label: 'Company', sortable: true },
        { key: 'thumbnail', label: 'Thumbnail', sortable: true },
        { key: 'review', label: 'Review', sortable: true },
        { key: 'type', label: 'Type', sortable: true },
        { key: 'created_at', label: 'Date/Time', sortable: true },
        { key: 'action', label: 'Actions', sortable: true },
        { key: 'id', label: '', sortable: false },
      ],
    }
  },
  computed: {
    ...mapGetters('reviews', ['reviews', 'pagination']),
    isDesc: {
      get() {
        return this.sort.toLowerCase() === 'desc' ? true : false
      },
      set(value) {
        return value
      },
    }
  },
  created() {
    this.getData()
  },
  methods: {
    ...mapActions('reviews', ['getReviewsAction', 'deleteReviewAction', 'deleteReviewsAction']),
    deleteVideos() {
			this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Yes, delete (${this.selected.length}) review(s)`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(({ value }) => {
        if (value) {
          this.loading = true
          this.deleteReviewsAction({ ids: this.selected }).finally(() => {
            this.loading = false
            this.selected = []
            this.getData()
          })
        }
      })
    },
    deleteVideo({ item }) {
			this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(({ value }) => {
        if (value) {
          this.deleteReviewAction(item.id).then(() => {
						this.showToast('Company Review Deleted Successfully!')
					})
        }
      })
    },
    getData() {
      this.isLoading = true
      this.getReviewsAction(`per_page=${this.mainPerPage}&current_page=${this.paginationCurrentPage}&attribute=${this.attribute}&sort=${this.sort}`).finally(() => {
        this.isLoading = false
      })
    },
    paginate(page) {
      this.isLoading = true
      this.paginationCurrentPage = page
      this.getData()
    },
		playVideo({ item }) {
			this.video = item
			this.$refs['review-video'].show()
		},
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.w-34 {
    width: 34px;
    margin-left: calc(-5.1px);
}
</style>
