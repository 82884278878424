<template>
  <div class="ghost-video-player">
    <video-player
      ref="reviewVideoPlayer"
      class="video-player-box vjs-flat-skin"
      :options="playerOptions"
      :playsinline="true"
      :cross-origin="'*'"
      @play="onPlayerPlay($event)"
      @pause="onPlayerPause($event)"
      @statechanged="playerStateChanged($event)"
      @ready="playerReadied"
      @ended="onPlayerEnded($event)"
      @waiting="onPlayerWaiting($event)"
      @playing="onPlayerPlaying($event)"
      @loadeddata="onPlayerLoadeddata($event)"
      @timeupdate="onPlayerTimeupdate($event)"
      @canplay="onPlayerCanplay($event)"
      @canplaythrough="onPlayerCanplaythrough($event)"
    />
  </div>
</template>

<script>
/* eslint-disable */
import 'video.js/dist/video-js.css'
import { appUrl } from '@/config/apiConfig';
import { videoPlayer } from 'vue-video-player'

export default {
    props: {
        autoplay: {
            type: [Boolean],
            default: true
        },
        src: {
            type: [String],
            default: null
        },
        poster: {
            type: [String, null],
            default: null
        },
        token: {
            type: [String],
            default: null
        },
    },
    components: {
        videoPlayer,
    },
    data () {
        return {
            appUrl,
            playerOptions: {
                autoplay: this.autoplay,
                muted: false,
                width: 600,
                height: 260,
                // language: 'en',
                nativeTextTracks: false,
                nativeAudioTracks: false,
                playbackRates: [0.7, 1.0, 1.5, 2.0]
            }
        };
    },
    watch: {
        src: {
            deep: true,
            immediate: true,
            handler(newValue) {
				if (newValue) {
					const fileExt = newValue.split('.').pop()
					if (fileExt == 'm3u8') {
						this.playerOptions.sources = [{
							src: `${this.appUrl}${newValue}`,
							type: 'application/x-mpegURL'
						}]
					} else {
						this.playerOptions.sources = [{
							src: `${this.appUrl}${newValue}`,
							type: 'video/mp4'
						}]
					}

                    this.playerOptions.poster = `${this.appUrl}${this.poster}`

                    this.$forceUpdate()
                }
            }
        }
    },
    computed: {
        player() {
            return this.$refs.reviewVideoPlayer.player
        }
    },
    methods: {
        onPlayerPlay(player) {
            this.videoPlayerLog('onPlayerPlay', player)
        },
        onPlayerPause(player) {
			this.videoPlayerLog('onPlayerPause', player)
        },
        playerStateChanged(playerCurrentState) {
			this.videoPlayerLog('playerStateChanged', playerCurrentState)
			if (playerCurrentState.error) {
				this.playerOptions.sources = [{
					src: `https://api.crmzz.com/review/video/review-${this.token}.mp4`,
					type: 'video/mp4'
				}]
			}
		},
        playerReadied(player) {
			this.videoPlayerLog('playerReadied', player)
        },
		onPlayerEnded(player) {
			this.videoPlayerLog('onPlayerEnded', player)
		},
		onPlayerWaiting(player) {
			this.videoPlayerLog('onPlayerWaiting', player)
		},
		onPlayerPlaying(player) {
			this.videoPlayerLog('onPlayerPlaying', player)
		},
		onPlayerLoadeddata(player) {
			this.videoPlayerLog('onPlayerLoadeddata', player)
		},
		onPlayerTimeupdate(player) {
			this.videoPlayerLog('onPlayerTimeupdate', player)
		},
		onPlayerCanplay(player) {
			this.videoPlayerLog('onPlayerCanplay', player)
		},
		onPlayerCanplaythrough(player) {
			this.videoPlayerLog('onPlayerCanplaythrough', player)
		},
		videoPlayerLog(methodName, data) {
			// console.log({ from: methodName, data })
		}
    },
    beforeDestroy () { }
}
</script>

<style lang="scss">
.ghost-video-player {

    .video-player-box {
		[id^="vjs_video"] {
			min-height: fit-content!important;
		}
	}

	.plyr--video .plyr__controls .plyr__control.plyr__tab-focus,
    .plyr--video .plyr__controls .plyr__control:hover,
    .plyr--video .plyr__controls .plyr__control[aria-expanded="true"],
    .plyr--video .plyr__control.plyr__tab-focus,
    .plyr--video .plyr__control.plyr__control--overlaid {
        background: linear-gradient(to left, #F27121, #E94057, #8A2387)!important;
        color: #fff;
    }
    &.video-player-box {
        div.video-js {
            margin: 0!important;
            padding: 0!important;
            width: 100%!important;
            display: block!important;
            // height: 525px!important;
            height: 85vh!important;
        }
    }
    .video-js {
        font-size: 14px;
        .vjs-control-bar {
            // background: linear-gradient(to left, #F27121, #E94057, #8A2387)!important;
            color: #fff;
        }

        .vjs-control,
        .vjs-play-control {
            outline: none!important;
            border: 0!important;
        }

        .vjs-big-play-button {
            border: none!important;
            background: transparent!important;
            outline: none!important;
            top: 40%!important;
            right: 0!important;
            left: 0!important;
            bottom: 0!important;
            margin: 0 auto!important;
            text-align: center;

            .vjs-icon-placeholder {
                font-size: 1.5em!important;
                background: transparent!important;

                &:before {
                    font-family: FontAwesome!important;
                    content: "\f144"!important;
                    font-size: 3.2rem;
                    background-color: #E94057;
                    background-image: linear-gradient(to left, #F27121, #E94057, #8A2387);
                    background-size: 100%;
                    -webkit-background-clip: text;
                    -moz-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    -moz-text-fill-color: transparent;
                }
            }
        }
    }

	&.brand-page,
	&.user-page {
		.video-player-box {
			[id^="vjs_video"] {
				min-height: 100vh!important;
			}
		}
	}
}
</style>
